import {
  PayloadAction
} from "@reduxjs/toolkit";
import {
  all,
  put,
  select,
  takeEvery
} from "redux-saga/effects";

import { endpoints } from "../../api/api";
import {
  TApiInterestAndTaxPaginatedPayload
} from "../../api/types/clientPortal";
import { setInterestEarnedRangeByEid } from "../app/appReducers";

import {
  selectNumMonthsOfInterestEarnedByActiveEid
} from "./positionsSelectors";



export function* setBrushRangeSaga(action: PayloadAction<TApiInterestAndTaxPaginatedPayload>) {
  const { payload } = action
  const eid = payload.data.at(0)?.account;

  if (!eid) return;

  // TODO: this only gets the active one, which I think will always work, but there may be issues
  const monthsOfInterest: ReturnType<typeof selectNumMonthsOfInterestEarnedByActiveEid>
    = yield select(selectNumMonthsOfInterestEarnedByActiveEid)

  yield put(setInterestEarnedRangeByEid({
    eid,
    range: [0, monthsOfInterest - 1]
  }))
}

export function* positionSaga() {
  yield all([takeEvery(endpoints.getInterestAndTax.matchFulfilled, setBrushRangeSaga)]);
}
