import {
  type ToastOptions
} from "react-hot-toast"
import {
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit";


type Renderable = JSX.Element | string | null;
export type ToastPayload = {
  message: Renderable,
  options?: ToastOptions
}

export const toastsSlice = createSlice({
  name: 'toasts',
  initialState: null, // no state needed
  reducers: {
    success: (_state, _action: PayloadAction<ToastPayload>) => undefined,
    error: (_state, _action: PayloadAction<ToastPayload>) => undefined,
    loading: (_state, _action: PayloadAction<ToastPayload>) => undefined,
    custom: (_state, _action: PayloadAction<ToastPayload>) => undefined,
    dismiss: (_state, _action: PayloadAction<string | undefined>) => undefined,
    remove: (_state, _action: PayloadAction<string | undefined>) => undefined,
  }
})

export const {
  success: toastSuccess,
  error: toastError,
  loading: toastLoading,
  custom: toastCustom,
  dismiss: dismissToast,
  remove: removeToast
} = toastsSlice.actions

export default toastsSlice.reducer