/* eslint-disable react/prop-types */
import React from "react"
import { cn } from "@monetarymetals/react-ux";


const URL = `${import.meta.env.VITE_CDN_BASE_URL}/assets/monetary-metals.webp`;

export default function MMLogo({ className, ...props }: React.ImgHTMLAttributes<HTMLImageElement>) {
  return (
    <img
      src={URL}
      alt="Monetary Metals Logo"
      className={cn("aspect-[180/41]", className)}
      width={900}
      height={205}
      {...props}
    />
  );
}