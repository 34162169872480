import { all, put, takeLatest } from "redux-saga/effects";

import { api } from "../api/api";

import { signOutUser as signOutUserAnalytics } from "./analytics/analyticsReducers";
import { signOutUser as signOutUserApp } from "./app/appReducers";
// eslint-disable-next-line import/no-cycle
import { appSaga } from "./app/appSaga";
import { positionSaga } from "./positions/positionsSaga";
import { toastsSaga } from "./toasts/toastsSaga";

function* signOutUserSaga() {
  yield all([put(api.util.resetApiState()), put(signOutUserApp()), put(signOutUserAnalytics())]);
}

export default function* rootSagas() {
  yield all([appSaga(), positionSaga(), toastsSaga(), takeLatest("signOutUser", signOutUserSaga)]);
}
