import { useCallback, useRef, useState } from "react";

/**
 * A utility hook to set a loading state for a minimum amount of time
 * Useful for preventing flickering of a loading state
 * @example
 * const [isLoading, startTimer, timerPromise] = useMinLoadTime(5000);
 * const someAsyncFunction = async () => {
 *     startTimer();
 *     // some action that takes an unknown amount of time
 *     await someAsyncAction();
 *     await timerPromise(); // called after someAsyncAction has completed
 *     // isLoading will be false at this point, and at least 5000ms have passed
 *   }
 * };
 * @param minLoadTime minimum time in milliseconds to wait before setting isLoading to false
 * @returns [isLoading, startTimer, timerPromise]
 */
export const useMinLoadTime = (minLoadTime: number) => {
  const [isLoading, setIsLoading] = useState(false);
  const timerStartTime = useRef(0);
  const startTimer = useCallback(() => {
    timerStartTime.current = Date.now();
    setIsLoading(true);
  }, [setIsLoading]);
  const timerPromise = useCallback(async () => {
    if (timerStartTime.current === 0) {
      // eslint-disable-next-line no-console
      console.warn("Timer not started");
    }
    const elapsedTime = Date.now() - timerStartTime.current;

    if (elapsedTime < minLoadTime) {
      await new Promise((resolve) => {
        setTimeout(resolve, minLoadTime - elapsedTime);
      });
    }
    setIsLoading(false);
  }, [setIsLoading]);

  return [isLoading, startTimer, timerPromise] as const;
};
