/* eslint-disable react/prop-types */
import { ComponentProps, ReactNode } from "react";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";

import GenericErrorPage from "../GenericErrorPage";

import StaleContentFallback from "./StaleContentFallback";

interface ErrorBoundaryProps {
  children: ReactNode;
}

type SentryFallback = ComponentProps<typeof SentryErrorBoundary>["fallback"];
const FallbackComponent: SentryFallback = ({ error }) => {
  if (error instanceof Error && error.message.match(/Failed to fetch dynamically imported module/i)) {
    return <StaleContentFallback />;
  }

  return <GenericErrorPage />;
};

const ErrorBoundary = ({ children }: ErrorBoundaryProps) => (
  <SentryErrorBoundary fallback={FallbackComponent}>{children}</SentryErrorBoundary>
);

export default ErrorBoundary;
