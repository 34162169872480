import {
  TFunction
} from "react-i18next"


export const getIsoDateString = (date: Date) => date.toISOString().split('T')[0];

export const fromDateToString = (date: Date, day = 15) => ({
  dateStr: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`,
  month: `${date.getMonth() + 1}`.padStart(2, '0'),
  year: `${date.getFullYear()}`
})

export const fromStringToDateStringObj = (dateStr: string) => {
  const date = new Date(dateStr)

  return fromDateToString(date)
}

export const fromStringToMonthYearString = (dateStr: string) => {
  const date = new Date(dateStr)
  const [, month, , year] = date.toDateString().split(' ')

  return `${month} ${year}`
}

export const fromStringToDate = (dateStr: string) => {
  const split = dateStr.split('-');

  // TODO: Remove after May 2023


  const dateObject = {
    year: Number(split[0]),
    month: Number(split[1])-1,
    day: Number(split[2])
  }


  return new Date(dateObject.year, dateObject.month, dateObject.day)
}


export const dateBetween = (date1: Date, date2: Date, size = 12 ) => {
  if(date1 > date2) return []

  if(date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear())
    return [date1]

  const months = (date2.getFullYear() - date1.getFullYear()) * 12 - date1.getMonth() + date2.getMonth()

  if(!months || months < 1) return []

  return [...Array(months+1).keys()].slice(0, size).map((offset) => {
    const dateObj = new Date(date2.getTime())
    const month = dateObj.getMonth()

    dateObj.setDate(1)
    dateObj.setMonth(month-offset)

    return dateObj
  })


}

export const getEachDayOfMonthBetween = (start: string, end: string, day = 15): string[] => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const result: string[] = [];

  // Loop through all months between the start and end dates
  const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), day);

  while (currentDate <= endDate) {
    result.push(currentDate.toISOString().split('T')[0]);
    // Move to the next month
    currentDate.setMonth(currentDate.getMonth() + 1);
    currentDate.setDate(day);
  }

  return result;
}

export const formatDuration = (t: TFunction, startDateStr: string, endDateSr: string): string => {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateSr);

  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();
  const startMonth = startDate.getMonth();
  const endMonth = endDate.getMonth();

  const yearDiff = endYear - startYear;
  const monthDiff = endMonth - startMonth;
  const totalMonths = yearDiff * 12 + monthDiff;

  const total = totalMonths % 12 === 0 ? totalMonths / 12 : totalMonths;

  const unit = totalMonths % 12 === 0 ? 'year' : 'month';

  return t(`common:${unit}`, {
    count: total,
  })
}

export const formatRelativeTime = (t: TFunction, startDate: Date, endDate: Date, style = "narrow"): string => {
  const diff = endDate.getTime() - startDate.getTime();
  const absDiff = Math.abs(diff);
  let val;
  let unit;

  if (absDiff < 1000 * 60) {
    val = Math.floor(diff / 1000);
    unit = "second";
  } else if (absDiff < 1000 * 60 * 60) {
    val = Math.floor(diff / (1000 * 60));
    unit = "minute";
  } else if (absDiff < 1000 * 60 * 60 * 24) {
    val = Math.floor(diff / (1000 * 60 * 60));
    unit = "hour";
  } else if (absDiff < 1000 * 60 * 60 * 24 * 7) {
    val = Math.floor(diff / (1000 * 60 * 60 * 24));
    unit = "day";
  } else if (absDiff < 1000 * 60 * 60 * 24 * 30) {
    val = Math.floor(diff / (1000 * 60 * 60 * 24 * 7));
    unit = "week";
  } else if (absDiff < 1000 * 60 * 60 * 24 * 365) {
    val = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));
    unit = "month";
  } else {
    val = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));
    unit = "year";
  }

  return t("relativeDate", {
    val,
    range: unit,
    style,
  });
};