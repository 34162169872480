import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@monetarymetals/react-ux";

type PasswordValidationTooltipProps = {
  password: string;
  children: ReactNode;
};

export const PASSWORD_VALIDATION_RULES = [
  {
    labelKey: "passwordValidation.eightCharacters",
    regex: /.{8,}/,
  },
  {
    labelKey: "passwordValidation.oneUppercase",
    regex: /[A-Z]/,
  },
  {
    labelKey: "passwordValidation.oneLowercase",
    regex: /[a-z]/,
  },
  {
    labelKey: "passwordValidation.oneNumber",
    regex: /[0-9]/,
  },
  {
    labelKey: "passwordValidation.oneSpecialCharacter",
    regex: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
  },
  {
    labelKey: "passwordValidation.noSpaces",
    regex: /^\S*$/,
  },
];

export default function PasswordValidationTooltip({ password, children }: PasswordValidationTooltipProps) {
  const { t } = useTranslation("common");
  const [focused, setFocused] = useState(false);
  const rulesStatus = PASSWORD_VALIDATION_RULES.map(({ labelKey, regex }) => ({
    label: t(labelKey),
    valid: regex.test(password),
  }));

  return (
    <TooltipProvider>
      <Tooltip open={password.length > 0 && focused}>
        <TooltipTrigger asChild onFocus={() => setFocused(true)} onBlur={() => setFocused(false)}>
          {children}
        </TooltipTrigger>
        <TooltipContent side="bottom" className="p-4">
          <div className="flex flex-col">
            <p className="mb-2 text-sm font-medium text-charcoal">{t("passwordValidation.title")}</p>
            <ul className="list-inside list-disc">
              {rulesStatus.map(({ label, valid }) => (
                <li key={label} className="mb-2 flex list-none items-center gap-1">
                  {valid ? (
                    <CheckIcon className="h-4 w-auto text-green-500" />
                  ) : (
                    <XMarkIcon className="h-4 w-auto text-red-500" />
                  )}
                  {label}
                </li>
              ))}
            </ul>
          </div>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
