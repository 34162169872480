/* eslint-disable max-len */
import {
  Auth
} from "@aws-amplify/auth"


const {
  VITE_REGION,
  VITE_USER_POOL_ID,
  VITE_USER_POOL_WEB_CLIENT_ID,
  VITE_API_GATEWAY_URL,
} = import.meta.env;

const config = {
  API: {
    endpoints: [
      {
        name: "apiGateway",
        endpoint: VITE_API_GATEWAY_URL,
        custom_header: async () => ({
          Authorization: `${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        }),
      },
    ],
  },
  Auth: {
    // REQUIRED - Amazon Cognito Region=
    region: VITE_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: VITE_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: VITE_USER_POOL_WEB_CLIENT_ID,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the transmission requires a secure protocol
    // cookieStorage: {
    //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //   domain: 'localhost',
    //   // OPTIONAL - Cookie path
    //   path: '/',
    //   // OPTIONAL - Cookie expiration in days
    //   expires: 365,
    //   // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //   sameSite: 'lax',
    //   // OPTIONAL - Cookie secure flag
    //   // Either true or false, indicating if the cookie transmission requires https.
    //   secure: false,
    // },
    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: "USER_SRP_AUTH"
  },
};

export default config;
