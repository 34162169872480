/* eslint-disable max-len */
import { useState } from "react";
import { useSelector } from "react-redux";
import { Dialog, DialogContent, DialogTrigger } from "@monetarymetals/react-ux";
import { captureFeedback } from "@sentry/react";

import { useSendFeedbackSubmissionSuccessEmailMutation } from "../api/api";
import { useMinLoadTime } from "../common/hooks/useMinLoadTime";
import { selectMaybeEmail, selectUserName } from "../redux/app/appSelectors";

import FeedbackDialogPrompt from "./FeedbackDialogPrompt";
import FeedbackDialogResponse from "./FeedbackDialogResponse";

type FeedbackDialogProps = {
  children?: React.ReactNode;
  source: "feedbackLink" | "errorBoundary";
  isOpen?: boolean;
  onClose?: () => void;
};

export default function FeedbackDialog({ children, source, isOpen, onClose }: FeedbackDialogProps) {
  const [feedback, setFeedback] = useState("");
  const [showResponse, setShowResponse] = useState(false);
  const [sendFeedbackSubmissionSuccessEmail, { isSuccess }] = useSendFeedbackSubmissionSuccessEmailMutation();
  const [isLoading, startTimer, timerPromise] = useMinLoadTime(1500);

  const email = useSelector(selectMaybeEmail);
  const username = useSelector(selectUserName);

  const handleSendFeedback = async () => {
    if (feedback.trim()) {
      captureFeedback(
        {
          name: username ?? undefined,
          email: email ?? undefined,
          message: feedback,
        },
        {
          includeReplay: true,
        },
      );
      try {
        startTimer();
        await sendFeedbackSubmissionSuccessEmail({ username: username!, feedback }).unwrap();
      } finally {
        await timerPromise();
        setShowResponse(true);
      }
    }
  };

  const handleCloseDialog = () => {
    setShowResponse(false);
    setFeedback("");
    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleCloseDialog}>
      {isOpen === undefined && <DialogTrigger className="cursor-pointer text-left">{children}</DialogTrigger>}
      <DialogContent>
        {!showResponse ? (
          <FeedbackDialogPrompt
            source={source}
            feedback={feedback}
            isLoading={isLoading}
            onFeedbackChange={(e) => setFeedback(e.target.value)}
            onSendFeedback={handleSendFeedback}
          />
        ) : (
          <FeedbackDialogResponse source={source} isSuccess={isSuccess} />
        )}
      </DialogContent>
    </Dialog>
  );
}
