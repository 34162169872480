import { useTranslation } from "react-i18next";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { Button, Card } from "@monetarymetals/react-ux";

export default function StaleContentFallback() {
  const { t } = useTranslation("common");

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-gray-200">
      <div className="container max-w-xl">
        <Card className="flex flex-col items-center gap-4 p-8">
          <ArrowPathIcon className="h-9 w-auto text-muted-foreground" />
          <h1 className="text-center text-xl">{t("refreshPage.title")}</h1>
          <p className="text-center [textWrap:balance]">{t("refreshPage.message")}</p>
          <Button className="w-full" onClick={() => window.location.reload()}>
            {t("refreshPage.button")}
          </Button>
        </Card>
      </div>
    </div>
  );
}
