import { type FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CognitoUser } from "@aws-amplify/auth";
import { AsyncButton, Button } from "@monetarymetals/react-ux";
import { Auth } from "aws-amplify";

import PasswordValidationTooltip from "../../@mm-ux/components/PasswordValidationTooltip";

import AuthPage from "./AuthPage";
import PasswordInput from "./PasswordInput";

export default function NewPassword({
  setChallengedUser,
  challengedUser,
}: {
  setChallengedUser: (challengedUser: CognitoUser | null) => void;
  challengedUser: CognitoUser;
}) {
  const { t } = useTranslation("common");
  const [newPassword, setNewPassword] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleNewPassword = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const password = formData.get("new-password") as string;
    const confirmPassword = formData.get("confirm-password") as string;

    if (password !== confirmPassword) {
      setError(t("awsAmplifyErrors.Passwords do not match"));

      return;
    }

    setError("");
    setLoadingSubmit(true);
    Auth.completeNewPassword(challengedUser, password)
      .then(() => {
        Auth.signIn({
          username: challengedUser.getUsername?.() ?? "",
          password,
        })
          .then(setChallengedUser)
          .catch((err) => {
            setError(err.message);
          })
          .finally(() => setLoadingSubmit(false));
      })
      .catch((err) => {
        setError(err.message);
        setLoadingSubmit(false);
      });
  };

  return (
    <AuthPage title={t("main-navigation.reset-password")}>
      <form className="flex flex-col gap-4" onSubmit={handleNewPassword}>
        <PasswordValidationTooltip password={newPassword}>
          <PasswordInput
            placeholder={t("labels.enterYourNewPassword")}
            autoComplete="new-password"
            name="new-password"
            required
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </PasswordValidationTooltip>
        <PasswordInput
          placeholder={t("labels.confirmYourNewPassword")}
          autoComplete="new-password"
          name="confirm-password"
          required
        />
        {error && <p className="text-sm text-red-500">{error}</p>}
        <AsyncButton type="submit" loading={loadingSubmit} loadingText={t("button.submitting")}>
          {t("button.submit")}
        </AsyncButton>
        <Button
          variant="link"
          onClick={() => {
            navigate("/");
            setChallengedUser(null);
          }}
        >
          {t("button.backToSignIn")}
        </Button>
      </form>
    </AuthPage>
  );
}
