import {
  createSlice
}
  from "@reduxjs/toolkit";

import {
  AnalyticSliceState
} from './analyticsTypes'


const initialState: AnalyticSliceState = {
  isLoading: false,
  hasErrors: false,
  errorMessage: '',
  data: 'analytics_data'
}

export const analyticsSlice = createSlice({
  name: 'analytic',
  initialState,
  reducers: {
    signOutUser: () => initialState,
    setData: (state: AnalyticSliceState, action) => {
      state.data = action.payload;
    },
    setLoading: (state: AnalyticSliceState, action) => {
      state.isLoading = action.payload;
    },
    setError: (state: AnalyticSliceState, action) => {
      state.isLoading = false;
      state.hasErrors = false;
      state.errorMessage = action.payload;
    }
  },
});

export const { signOutUser, setData, setLoading, setError } = analyticsSlice.actions;

export default analyticsSlice.reducer;
