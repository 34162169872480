import { type FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AsyncButton, Button, Input } from "@monetarymetals/react-ux";
import { Auth } from "aws-amplify";

import PasswordValidationTooltip from "../../@mm-ux/components/PasswordValidationTooltip";

import AuthPage from "./AuthPage";
import PasswordInput from "./PasswordInput";
import { type ChallengedUser } from "./Router";

export default function ResetPassword({
  email,
  setChallengedUser,
}: {
  email: string;
  setChallengedUser: (challengedUser: ChallengedUser | null) => void;
}) {
  const { t } = useTranslation("common");
  const [newPassword, setNewPassword] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleResetPassword = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const code = formData.get("code") as string;
    const password = formData.get("new-password") as string;
    const confirmPassword = formData.get("confirm-password") as string;

    if (password !== confirmPassword) {
      setError(t("awsAmplifyErrors.Passwords do not match"));

      return;
    }

    setError("");
    setLoadingSubmit(true);
    Auth.forgotPasswordSubmit(email, code, password)
      .then(() => {
        Auth.signIn({
          username: email,
          password,
        })
          .then(setChallengedUser)
          .catch((err) => {
            setError(err.message);
          })
          .finally(() => setLoadingSubmit(false));
      })
      .catch((err) => {
        setError(err.message);
        setLoadingSubmit(false);
      });
  };

  const handleResendCode = async () => {
    setError("");
    setLoadingResend(true);
    Auth.forgotPassword(email)
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => setLoadingResend(false));
  };

  return (
    <AuthPage title={t("main-navigation.reset-password")}>
      <form className="flex flex-col gap-4" onSubmit={handleResetPassword}>
        <Input
          type="number"
          placeholder={t("labels.enterYourRecoveryCode")}
          name="code"
          autoComplete="one-time-code"
          required
        />
        <PasswordValidationTooltip password={newPassword}>
          <PasswordInput
            placeholder={t("labels.enterYourNewPassword")}
            autoComplete="new-password"
            name="new-password"
            required
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </PasswordValidationTooltip>
        <PasswordInput
          placeholder={t("labels.confirmYourNewPassword")}
          autoComplete="new-password"
          name="confirm-password"
          required
        />
        {error && <p className="text-sm text-red-500">{error}</p>}
        <AsyncButton type="submit" loading={loadingSubmit} loadingText={t("button.submitting")}>
          {t("button.submit")}
        </AsyncButton>
        <AsyncButton
          variant="ghost"
          loading={loadingResend}
          loadingText={t("button.resendingCode")}
          onClick={handleResendCode}
        >
          {t("button.resendCode")}
        </AsyncButton>
        <Button
          variant="link"
          onClick={() => {
            navigate("/");
            setChallengedUser(null);
          }}
        >
          {t("button.backToSignIn")}
        </Button>
      </form>
    </AuthPage>
  );
}
