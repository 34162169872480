import { lazy } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import { wrapCreateBrowserRouter } from "@sentry/react";

import { withViewSettings } from "../components/hoc/WithViewSettings";

const AuthenticatedView = lazy(() =>
  import("../views/AuthenticatedView").then((module) => ({ default: module.AuthenticatedView })),
);

const BalanceView = lazy(() =>
  import("../views/BalanceView").then((module) => ({
    default: withViewSettings({
      title: "balance",
      permissions: "balance",
    })(module.BalanceView),
  })),
);

const StatementListView = lazy(() =>
  import("../views/StatementListView").then((module) => ({
    default: withViewSettings({
      title: "statement-list",
      permissions: "statement",
    })(module.StatementListView),
  })),
);

const InvestmentView = lazy(() =>
  import("../views/YieldView").then((module) => ({
    default: withViewSettings({
      title: "investment",
      permissions: "investment",
    })(module.YieldView),
  })),
);

const SettingsPageView = lazy(() =>
  import("../views/SettingsPageView").then((module) => ({
    default: withViewSettings({
      title: "settings",
      permissions: null,
    })(module.SettingsPage),
  })),
);

const ManageCookiesForm = lazy(() => import("../components/ManageCookiesForm"));
const UserAttributesChangeForm = lazy(() => import("../components/UserAttributesChangeForm"));
const MFAManageForm = lazy(() => import("../components/auth/MFAManageForm"));
const PasswordForm = lazy(() => import("../components/PasswordForm"));
const ManagePaymentMethods = lazy(() =>
  import("../components/PaymentMethods/ManagePaymentMethods").then((module) => ({
    default: withViewSettings({
      title: "payment-methods",
      permissions: "settings.payment-methods",
    })(module.default),
  })),
);
const NewPaymentMethod = lazy(() =>
  import("../components/PaymentMethods/NewPaymentMethod").then((module) => ({
    default: withViewSettings({
      title: "new-payment-method",
      permissions: "settings.payment-methods",
    })(module.default),
  })),
);
const PlaidOAuthCallback = lazy(() =>
  import("../components/PaymentMethods/PlaidOAuthCallback").then((module) => ({
    default: withViewSettings({
      title: "plaid-oauth-callback",
      permissions: "settings.payment-methods",
    })(module.default),
  })),
);
const FinalizePlaidPaymentMethod = lazy(() =>
  import("../components/PaymentMethods/FinalizePlaidPaymentMethod").then((module) => ({
    default: withViewSettings({
      title: "finalize-plaid-payment-method",
      permissions: "settings.payment-methods",
    })(module.default),
  })),
);

const TradeView = lazy(() =>
  import("../views/TradeView").then((module) => ({
    default: withViewSettings({
      title: "trade",
      permissions: "trade",
    })(module.TradeView),
  })),
);
const PurchaseLayout = lazy(() =>
  import("../components/Trade/Purchase/PurchaseLayout").then((module) => ({
    default: withViewSettings({
      title: "purchase",
      permissions: "trade.purchase",
    })(module.default),
  })),
);
const PurchaseMetalTransaction = lazy(() =>
  import("../components/Trade/Purchase/PurchaseMetal").then((module) => ({
    default: withViewSettings({
      title: "purchase",
      permissions: "trade.purchase",
    })(module.PurchaseMetalTransaction),
  })),
);
const RecurringPurchases = lazy(() =>
  import("../components/Trade/Purchase/Recurring/RecurringPurchases").then((module) => ({
    default: withViewSettings({
      title: "purchase",
      permissions: "trade.purchase.recurring",
    })(module.default),
  })),
);
const NewScheduleForm = lazy(() =>
  import("../components/Trade/Purchase/Recurring/NewScheduleForm").then((module) => ({
    default: withViewSettings({
      title: "purchase",
      permissions: "trade.purchase.recurring",
    })(module.default),
  })),
);
const EditScheduleForm = lazy(() =>
  import("../components/Trade/Purchase/Recurring/EditScheduleForm").then((module) => ({
    default: withViewSettings({
      title: "purchase",
      permissions: "trade.purchase.recurring",
    })(module.default),
  })),
);
const SendMetal = lazy(() =>
  import("../components/Trade/SendMetal").then((module) => ({
    default: withViewSettings({
      title: "send",
      permissions: "trade.send",
    })(module.SendMetal),
  })),
);
const PendingTransaction = lazy(() =>
  import("../components/Trade/PendingTransaction").then((module) => ({
    default: withViewSettings({
      title: "pending",
      permissions: "trade.pending",
    })(module.PendingTransaction),
  })),
);
const PendingTransactions = lazy(() =>
  import("../components/Trade/PendingTransactions").then((module) => ({
    default: withViewSettings({
      title: "pending",
      permissions: "trade.pending",
    })(module.PendingTransactions),
  })),
);

const SpreadTableView = lazy(() =>
  import("../views/SpreadTableView").then((module) => ({
    default: withViewSettings({
      title: "spreads-table",
      permissions: null,
    })(module.FeeTableView),
  })),
);

const OpportunitiesView = lazy(() =>
  import("../views/OpportunitiesView").then((module) => ({
    default: withViewSettings({
      title: "opportunities",
      permissions: "opportunities",
    })(module.OpportunitiesView),
  })),
);

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const authenticatedRouter = sentryCreateBrowserRouter([
  {
    element: <AuthenticatedView />,
    children: [
      { path: "*", element: <Navigate to="/balance" /> },
      { path: "balance", element: <BalanceView /> },
      { path: "opportunities", element: <OpportunitiesView /> },
      { path: "statement", element: <StatementListView /> },
      { path: "yield", element: <InvestmentView /> },
      {
        path: "trade",
        element: <TradeView />,
        children: [
          { index: true, element: <Navigate to="purchase" /> },
          {
            path: "purchase",
            element: <PurchaseLayout />,
            children: [
              { index: true, element: <PurchaseMetalTransaction /> },
              { path: "recurring", element: <RecurringPurchases /> },
              { path: "recurring/new", element: <NewScheduleForm /> },
              { path: "recurring/edit/:scheduleId", element: <EditScheduleForm /> },
            ],
          },
          { path: "send", element: <SendMetal /> },
          {
            path: "pending",
            element: <PendingTransactions />,
          },
          {
            path: "pending/:taskId",
            element: <PendingTransaction />,
          },
        ],
      },
      {
        path: "settings",
        element: <SettingsPageView />,
        children: [
          { index: true, element: <Navigate to="password" /> },
          { path: "password", element: <PasswordForm /> },
          { path: "profile", element: <UserAttributesChangeForm /> },
          { path: "mfa", element: <MFAManageForm /> },
          { path: "cookies", element: <ManageCookiesForm /> },
          { path: "payment-methods", element: <ManagePaymentMethods /> },
          { path: "payment-methods/callback", element: <PlaidOAuthCallback /> },
          { path: "payment-methods/new", element: <NewPaymentMethod /> },
          { path: "payment-methods/new/final-steps", element: <FinalizePlaidPaymentMethod /> },
        ],
      },
      { path: "spreads", element: <SpreadTableView /> },
    ],
  },
]);
