import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";
import { AsyncButton, Button, Input } from "@monetarymetals/react-ux";

import AuthPage from "./AuthPage";
import PasswordInput from "./PasswordInput";
import { useSetChallengedUser } from "./Router";

export default function SignIn() {
  const { setChallengedUser } = useSetChallengedUser();
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");

  const handleSignIn = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const username = formData.get("username") as string;
    const password = formData.get("password") as string;

    // if password contains whitespace
    if (/\s/.test(password)) {
      setError(t("awsAmplifyErrors.Password contains whitespace"));

      return;
    }

    setError("");
    setLoading(true);
    Auth.signIn({
      username,
      password,
    })
      .then((result) => {
        setChallengedUser(result);
      })
      .catch((err) => {
        if (err.code === "PasswordResetRequiredException") {
          setChallengedUser({
            challengeName: "RESET_PASSWORD",
            email: username,
          });

          return;
        }
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <AuthPage>
      <form className="flex flex-col gap-4" onSubmit={handleSignIn}>
        <Input type="email" autoComplete="username" placeholder={t("Enter your Email")} name="username" required />
        <PasswordInput
          autoComplete="current-password"
          placeholder={t("Enter your Password")}
          name="password"
          required
        />
        {error && <p className="text-sm text-red-500">{error}</p>}
        <div className="flex flex-col gap-2">
          <AsyncButton type="submit" loading={loading} loadingText={t("button.signingIn")} minLoadTime={1500}>
            {t("Sign In")}
          </AsyncButton>
          <Button asChild variant="link" type="button">
            <Link to="/forgot-password">{t("Forgot your password?")}</Link>
          </Button>
          <Button asChild variant="link" type="button" className="-mt-2">
            <a href="https://goldyield.monetary-metals.com/mmcpaccount" target="_blank" rel="noreferrer">
              {t("Not yet a client? Sign up today.")}
            </a>
          </Button>
        </div>
      </form>
    </AuthPage>
  );
}
