import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { initSentry } from "./monitoring/sentry";
import store from "./redux/store";
import App from "./App";

import "./index.css";

const container = document.getElementById("root");

const root = createRoot(container as Element);

initSentry();

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
