import { ToastBar, Toaster as RHTToaser } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { CheckCircleIcon, ExclamationCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Button } from "@monetarymetals/react-ux";

import { dismissToast } from "../redux/toasts/toastsReducers";

export default function Toaster() {
  const dispatch = useDispatch();

  return (
    <RHTToaser
      position="bottom-left"
      toastOptions={{
        duration: 6000,
        success: {
          icon: <CheckCircleIcon className="h-6 w-auto shrink-0 text-primary" />,
        },
        error: {
          icon: <ExclamationCircleIcon className="h-6 w-auto shrink-0 text-red-800" />,
        },
      }}
    >
      {(t) => (
        <ToastBar toast={t} position="bottom-left">
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== "loading" && (
                <Button
                  variant="ghost"
                  className="px-2 text-muted-foreground"
                  onClick={() => dispatch(dismissToast(t.id))}
                >
                  <XMarkIcon className="h-5 w-auto" />
                </Button>
              )}
            </>
          )}
        </ToastBar>
      )}
    </RHTToaser>
  );
}
