/* eslint-disable max-len */
import { useTranslation } from "react-i18next";
import { DialogDescription, DialogHeader, DialogTitle } from "@monetarymetals/react-ux";

type FeedbackDialogResponseProps = {
  source: "feedbackLink" | "errorBoundary";
  isSuccess: boolean;
};

export default function FeedbackDialogResponse({ source, isSuccess }: FeedbackDialogResponseProps) {
  const { t } = useTranslation("common");

  let title;
  let description;

  if (!isSuccess) {
    title = t("feedbackDialogResponse.title.error");
    description = t("feedbackDialogResponse.message.error");
  } else {
    title =
      source === "errorBoundary"
        ? t("feedbackDialogResponse.title.report-issue")
        : t("feedbackDialogResponse.title.feedback");
    description =
      source === "errorBoundary"
        ? t("feedbackDialogResponse.message.report-issue")
        : t("feedbackDialogResponse.message.feedback");
  }

  return (
    <DialogHeader>
      <DialogTitle>{title}</DialogTitle>
      <DialogDescription>{description}</DialogDescription>
    </DialogHeader>
  );
}
