export enum EnumCommodity {
  Gold = "gold",
  Silver = "silver",
  Usd = "usd",
  Palladium = "palladium",
  Platinum = "platinum"
}
export const ALL_COMMODITIES = [
  EnumCommodity.Gold,
  EnumCommodity.Silver,
  EnumCommodity.Usd,
  EnumCommodity.Palladium,
  EnumCommodity.Platinum
]

export type TCommodity = `${EnumCommodity}`

export type TWithCommodity = {
  commodity: TCommodity
}

export enum EnumAccounts {
  Storage = "hold/storage",
  Yield = "hold/yield",
  OnLease = "lessor/onlease",
  BondYield = "hold/bondYield",
  BondOnLoan = "bond_holder/onloan",
}

export type TAccounts = `${EnumAccounts}`

export enum EnumTransactEtfMethods {
  ACH = "ach",
  Wire = "wire",
  Unknown = "unknown"
}

export type TTransactEtfMethods = `${EnumTransactEtfMethods}`