import { PayloadAction } from "@reduxjs/toolkit";
import { all, put, select, takeLatest } from "redux-saga/effects";

import { endpoints } from "../../api/api";
import { TApiPolicyPayload } from "../../api/types/clientPortal";
import { ACCEPTED_COOKIES_KEY } from "../../common/util/compliantStorage";

import { setActiveAccountIndex } from "./appReducers";
import { selectAccountList, selectAclMenusByActiveEid, selectActiveAccountIndex } from "./appSelectors";

export function* fetchAccountInfoSaga(action: PayloadAction<TApiPolicyPayload>) {
  const accountList: ReturnType<typeof selectAccountList> = yield select(selectAccountList);
  const activeAccountIndex: ReturnType<typeof selectActiveAccountIndex> = yield select(selectActiveAccountIndex);
  const activeAccountEid = accountList[activeAccountIndex];

  if (!activeAccountIndex || activeAccountIndex < 0 || activeAccountIndex >= accountList.length) {
    yield put(setActiveAccountIndex(0));
  }

  const { cookieConsent } = action.payload.settings;
  const consent = cookieConsent?.consentGiven ?? false;

  if (typeof cookieConsent?.consentGiven !== "undefined") {
    localStorage.setItem(ACCEPTED_COOKIES_KEY, String(consent));
  }

  // this works, but the saga type and rtk query type are incompatible
  yield all(accountList.map((account) => put(endpoints.getAccountInfo.initiate(account) as never)));

  // pre-fetch data for the active account, if they have the correct acl menu
  if (!activeAccountEid) return;
  const aclMenusForActiveAccount: ReturnType<typeof selectAclMenusByActiveEid> =
    yield select(selectAclMenusByActiveEid);

  if (aclMenusForActiveAccount.includes("balance"))
    yield put(endpoints.getAccountPosition.initiate(activeAccountEid) as never);
  if (aclMenusForActiveAccount.includes("investment"))
    yield put(endpoints.getInterestAndTax.initiate(activeAccountEid) as never);
  if (aclMenusForActiveAccount.includes("opportunities"))
    yield put(endpoints.getOpportunities.initiate(activeAccountEid) as never);
}

export function* appSaga() {
  yield all([takeLatest(endpoints.getPolicyForCognitoUser.matchFulfilled, fetchAccountInfoSaga)]);
}
