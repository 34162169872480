import { createBrowserRouter } from "react-router-dom";
import { wrapCreateBrowserRouter } from "@sentry/react";

import ForgotPassword from "../components/auth/ForgotPassword";
import Router from "../components/auth/Router";
import SignIn from "../components/auth/SignIn";

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const unauthenticatedRouter = sentryCreateBrowserRouter([
  {
    element: <Router />,
    children: [
      { path: "/forgot-password", element: <ForgotPassword /> },
      { path: "*", element: <SignIn /> },
    ],
  },
]);
