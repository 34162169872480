// The order of this array will change the order of the balances in the UI
export const ACCOUNTS_THAT_CHANGE_THE_BALANCE = [
  'hold/yield',
  'lessor/onlease',
  'hold/bondYield',
  'bond_holder/onloan',
  'hold/storage'
] as const

export const ALL_ACCOUNTS = [
  ...ACCOUNTS_THAT_CHANGE_THE_BALANCE,
  "hold/settlement",
  "hold/tax",
  "lessor/allocation",
  "lessor/cma"
] as const