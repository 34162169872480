/* eslint-disable */
import { useTranslation } from "react-i18next";
import {
  AsyncButton,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Textarea,
} from "@monetarymetals/react-ux";

type FeedbackDialogPromptProps = {
  source: "feedbackLink" | "errorBoundary";
  feedback: string;
  isLoading: boolean;
  onFeedbackChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSendFeedback: () => void;
};

export default function FeedbackDialogPrompt({
  source,
  feedback,
  isLoading,
  onFeedbackChange,
  onSendFeedback,
}: FeedbackDialogPromptProps) {
  const { t } = useTranslation("common");

  const title =
    source === "errorBoundary"
      ? t("feedbackDialogPrompt.report-issue-title")
      : t("feedbackDialogPrompt.feedback-title");
  const description = source === "errorBoundary" ? null : t("feedbackDialogPrompt.message");

  return (
    <>
      <DialogHeader>
        <DialogTitle>{title}</DialogTitle>
        <DialogDescription>{description}</DialogDescription>
      </DialogHeader>
      <Textarea value={feedback} onChange={onFeedbackChange} />
      <DialogFooter>
        <AsyncButton variant="ghost" onClick={onSendFeedback} loading={isLoading}>
          {t("feedbackDialogPrompt.button.submit")}
        </AsyncButton>
      </DialogFooter>
    </>
  );
}
