import React from "react";
import { Card, CardContent } from "@monetarymetals/react-ux";

import MMLogo from "../MMLogo";

export default function AuthPage({ title, children }: { title?: string; children: React.ReactNode }) {
  return (
    <div className="container mx-auto flex h-screen w-full max-w-lg flex-col items-center gap-8 px-4 pt-16">
      <a href="https://monetary-metals.com/" target="_blank" rel="noreferrer" aria-label="Monetary Metals">
        <MMLogo />
      </a>
      <Card className="w-full">
        <CardContent className="p-8">
          {title && <h1 className="mb-4 font-medium">{title}</h1>}
          {children}
        </CardContent>
      </Card>
    </div>
  );
}

AuthPage.defaultProps = {
  title: "",
};
