import fromPairs from "lodash/fromPairs"
import map from "lodash/map"
import reduce from "lodash/reduce"
import toPairs from "lodash/toPairs"

import {
  TBalance
} from "../../@mm-ux/types/Common"
import {
  TReduxLondonFixData
} from "../../redux/app/appTypes"
import {
  ACCOUNTS_THAT_CHANGE_THE_BALANCE,
  ALL_ACCOUNTS 
} from "../constants/balances"
import {
  EnumCommodity,
  TCommodity,
  TWithCommodity
} from "../types/commodity"


export const calculateTotalBalanceFromPosition = (balance: TBalance) => (
  reduce(ACCOUNTS_THAT_CHANGE_THE_BALANCE, (acc, value) => acc + (balance[value] || 0), 0)
)

export const calculateTotalBalanceFromPositionForMultipleCommodities = (data: TBalance[]) =>{
  let totalValue = 0

  // eslint-disable-next-line no-return-assign
  data.map((balance: TBalance) => totalValue+=calculateTotalBalanceFromPosition(balance))

  return totalValue
}

export const convertToInputCommodityOz = <T extends TWithCommodity>(
  dataStructure: T[],
  paths: Array<Partial<keyof T>>,
  londonFixData: TReduxLondonFixData,
  commodityToConvertTo: TCommodity | null
): T[] => {
  if(!commodityToConvertTo) return dataStructure

  const pathsSet = new Set(paths)

  const convertedData = map(dataStructure, data => {
    const { commodity } = data
    const dataPair = toPairs(data)

    const convertedDataPair = map(dataPair, (pair) => {
      const [key, value] = pair
      let convertedValue: number | string = value

      if(pathsSet.has(key as keyof T) && !Number.isNaN(value)) {
        const commodityToConvertToValue = londonFixData[commodityToConvertTo]?.price || 1
        const commodityPrice = londonFixData[commodity]?.price || 1

        convertedValue = value as unknown as number * (commodityPrice/commodityToConvertToValue)
      }

      return [key, convertedValue]
    })

    return fromPairs(convertedDataPair) as T
  })

  return convertedData


}

export const getTotalBalanceForListOfBalance = (
  data: TBalance[],
  londonFixData:
  TReduxLondonFixData,
  commodity: TCommodity | null=EnumCommodity.Usd
) => {
  const usdData = convertToInputCommodityOz(
    data,
    ALL_ACCOUNTS as unknown as Array<Partial<keyof TBalance>>,
    londonFixData,
    commodity
  )

  return reduce(usdData, (curr, item) => curr +calculateTotalBalanceFromPosition(item), 0)
}
