import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { compliantLocalStorage } from "../../common/util/compliantStorage";
import { type AssetClass, type Conversion } from "../../components/PageControls/PageControls";
import { TOpportunityFilter } from "../opportunities/opportunityFilters";
import { TSetInterestEarnedRangePayloadAction } from "../positions/positionsTypes";

import { PlaidLinkPayload, TAppSliceState } from "./appTypes";

export const ACTIVE_ACCOUNT_KEY = "activeAccount";
export const ACTIVE_ASSET_KEY = "activeAssetClassPerPage";
export const ACTIVE_CONVERSION_KEY = "activeConversion";
export const SHOW_REVIEWED_KEY = "showReviewed";

export const DEFAULT_SETTINGS = {
  maintenance: false,
  ignoredOpportunities: {},
  signInsUntilMfaPrompt: 0,
};

export const initialAppState: TAppSliceState = {
  cognitoId: null,
  activeAccount: Number(compliantLocalStorage.getItem(ACTIVE_ACCOUNT_KEY) ?? "0"),
  email: null,
  appErrorMessage: null,
  pageTitle: "home",
  emulateUser: null,
  activeAssetClassByPage: (() => {
    const storageValue = compliantLocalStorage.getItem(ACTIVE_ASSET_KEY) ?? "{}";

    try {
      return JSON.parse(storageValue);
    } catch {
      return {};
    }
  })(),
  activeConversion: (compliantLocalStorage.getItem(ACTIVE_CONVERSION_KEY) as Conversion) ?? "usd",
  showReviewed: compliantLocalStorage.getItem(SHOW_REVIEWED_KEY) === "true",
  interestEarnedRangeByEid: {},
  plaidLinkPayload: null,
  opportunityFilters: [],
};

export const appSlice = createSlice({
  name: "app",
  initialState: initialAppState,
  reducers: {
    signOutUser: () => initialAppState,
    setActiveAccountIndex: (state: TAppSliceState, action: PayloadAction<number>) => {
      const { payload } = action;

      compliantLocalStorage.setItem(ACTIVE_ACCOUNT_KEY, String(payload));

      return {
        ...state,
        activeAccount: payload,
      };
    },
    setEmail: (state: TAppSliceState, action: PayloadAction<string>) => {
      const { payload } = action;

      return {
        ...state,
        email: payload,
      };
    },
    setCognitoId: (state: TAppSliceState, action: PayloadAction<string>) => {
      const { payload } = action;

      return {
        ...state,
        cognitoId: payload,
      };
    },
    setPageTitle: (state: TAppSliceState, action: PayloadAction<string>) => ({
      ...state,
      pageTitle: action.payload,
    }),
    setEmulateUser: (state: TAppSliceState, action: PayloadAction<string | null>) => ({
      ...state,
      emulateUser: action.payload,
    }),
    setActiveAssetClassByPage: (state, action: PayloadAction<{ page: string; assetClass: AssetClass }>) => {
      const newAssetClassByPage = {
        ...state.activeAssetClassByPage,
        [action.payload.page]: action.payload.assetClass,
      };

      compliantLocalStorage.setItem(ACTIVE_ASSET_KEY, JSON.stringify(newAssetClassByPage));

      return {
        ...state,
        activeAssetClassByPage: newAssetClassByPage,
      };
    },
    setActiveConversion: (state, action: PayloadAction<Conversion>) => {
      compliantLocalStorage.setItem(ACTIVE_CONVERSION_KEY, action.payload);

      return {
        ...state,
        activeConversion: action.payload,
      };
    },
    setShowReviewed: (state, action: PayloadAction<boolean>) => {
      compliantLocalStorage.setItem(SHOW_REVIEWED_KEY, action.payload.toString());

      return {
        ...state,
        showReviewed: action.payload,
      };
    },
    setInterestEarnedRangeByEid: (state, action: PayloadAction<TSetInterestEarnedRangePayloadAction>) => {
      const { eid, range } = action.payload;

      return {
        ...state,
        interestEarnedRangeByEid: {
          ...state.interestEarnedRangeByEid,
          [eid.replaceAll("-", "")]: range,
        },
      };
    },
    setPlaidLinkPayload: (state, action: PayloadAction<PlaidLinkPayload>) => {
      state.plaidLinkPayload = action.payload;

      return state;
    },
    clearPlaidLinkPayload: (state) => {
      state.plaidLinkPayload = null;

      return state;
    },
    setOpportunityFilters: (state, action: PayloadAction<TOpportunityFilter[]>) => {
      state.opportunityFilters = action.payload;

      return state;
    }
  },
});

export const {
  signOutUser,
  setActiveAccountIndex,
  setEmail,
  setCognitoId,
  setPageTitle,
  setEmulateUser,
  setActiveAssetClassByPage,
  setActiveConversion,
  setShowReviewed,
  setInterestEarnedRangeByEid,
  setPlaidLinkPayload,
  clearPlaidLinkPayload,
  setOpportunityFilters,
} = appSlice.actions;

export default appSlice.reducer;
